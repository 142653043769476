import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";

import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";

import Helmet from "../component/common/Helmet";
import {
  FiWifi,
  FiTrello,
  FiBriefcase,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import ContactOne from "../elements/contact/ContactOne";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--32",
    category: "The Era of Fiber Optic has just begun",
    title: "Fastest Fiber Internet",
    description: "At your service now with the Fastest Internet Speed",
    buttonText: "Get Your Connection",
    buttonLink: "#contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--31",
    category: "Limited Period Offer",
    title: "Free Installation",
    description:
      "Get the benefit of Free Installation and Huge Discount on all our packages now!",
    buttonText: "View Plans",
    buttonLink: "#pricing",
  },
];

const ServiceListOne = [
  {
    icon: <FiWifi />,
    title: "Broadband For Home",
    description:
      "First broadband operator in Area to offer upto 200 Mbps high speed connectivity.",
  },
  {
    icon: <FiBriefcase />,
    title: "Internet For Business",
    description:
      "Comprehensive end to end Enterprise IT & Connectivity solutions.",
  },
  {
    icon: <FiTrello />,
    title: "Developer Tie Up",
    description:
      "Broadband ready homes by building fiber infrastructure at zero cost to the developer",
  },
];

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "99% Internet Uptime",
      "High Speed Upload and Download",
      "Stream HD Content with ease",
      "Connect all your devices",
    ];
    var namesItemTwo = [
      "Secured Service",
      "No Hidden Charges",
      "Instant Response",
    ];

    return (
      <Fragment>
        <Helmet pageTitle="Sammy Vision Internet" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-white"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div id="home" className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick} autoplay>
              {/* autoplay */}
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div id="service" className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="#pricing">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Pricing Tbale Area  */}
        <div
          id="pricing"
          className="rn-pricing-table-area ptb--120 bg_color--5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Discover Our Best Plans</h2>
                  <p>
                    Get ready for more potential, more opportunity and more of
                    everything you expect from Sammy Vision. We’re bring gigabit
                    Internet to all our customers, starting in 2021.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">50 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹399</span>
                        <span className="subtitle">Per Month</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">50 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹2200</span>
                        <span className="subtitle">Six Months</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">50 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹3999</span>
                        <span className="subtitle">Yearly</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">100 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹699</span>
                        <span className="subtitle">Per Month</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">100 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹3500</span>
                        <span className="subtitle">Six Months</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mt--30">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">100 MBPS UNLIMITED</h4>
                      <div className="pricing">
                        <span className="price">₹6999</span>
                        <span className="subtitle">Yearly</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Home Broadband
                        </li>
                        <li>
                          <FiCheck /> 99% Internet Uptime
                        </li>

                        <li>
                          <FiCheck /> Support 24/7
                        </li>
                        <li>
                          <FiCheck /> Free Installation
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="#contact">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}

        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">XPON is available at ₹400</h4>
                <p>
                  We provide XPON devices for End to End Fiber connection at the
                  price of ₹400 (100% Refundable).
                </p>
                <p>
                  We also provide Routers as per your requirements at reasonable
                  price compare to market.
                </p>
                <a className="rn-btn btn-white" href="#contact">
                  Request Connection
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img
                  src="/assets/images/about/finding-us-01.webp"
                  alt="Finding Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start About Area  */}
        <div id="about" className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">We Are Particular</h2>
                    <p className="description">
                      Our mission lies in our brand name- we are the leaders
                      amongst the Broadband service providers in Mumbai who
                      focus on your VISION, customers needs. The internet is
                      what drives today’s society. People now require high-speed
                      internet for almost every task, which is why data
                      connectivity has become a necessity.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>The Speed that you need.</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>24/7 Support at your doorstep.</h4>
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-3.webp"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        {/* Start Counterup Area */}
        <div className="counterup-area ptb--120 bg-theme-gradient theme-text-white">
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandOne branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start call To Action  */}
        <CallAction id="contact" />
        {/* End call To Action  */}
        {/* Start COntact Area */}
        <div id="contact" className="fix">
          <div className="rn-contact-area ptb--120 bg_color--1">
            <ContactOne />
          </div>
        </div>
        {/* End COntact Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default HomePage;
