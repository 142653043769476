import React from "react";
import { FaLocationArrow, FaPhone, FaWhatsapp } from "react-icons/fa";

const SocialShare = [
  {
    Social: <FaWhatsapp />,
    link: "https://wa.me/919082151582?text=I'm%20interested%20in%20your%20internet%20connection",
  },
  { Social: <FaPhone />, link: "tel:+919082151582" },
  {
    Social: <FaLocationArrow />,
    link: "https://www.google.com/maps/dir//Shri%20Yogeshwar%20Co%20Operative%20Housing%20Society",
  },
];

const FooterTwo = () => {
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img src="/assets/images/logo/logo.png" alt="Logo images" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>
                  Copyright © 2021{"  "}
                  <a
                    href="https://rostudio.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="color-whites"
                  >
                    roStudio.in
                  </a>{" "}
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
