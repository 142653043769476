import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line no-unused-vars
import AutoSuggestStyles from "./AutoSuggest.css";
//rohan start
import Autosuggest from "react-autosuggest";

// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
  {
    name: "Heramb Darshan",
    year: 1972,
  },
  {
    name: "Yogeshwar Housing Society",
    year: 2012,
  },
  {
    name: "Jay Krupa Housing Society",
    year: 2012,
  },
  {
    name: "Mohankheda Housing Society",
    year: 2012,
  },
  {
    name: "New Hind Mill Colony, Mhada",
    year: 2012,
  },
  {
    name: "Maruti Mali Chawl",
    year: 2012,
  },
  {
    name: "Kapreshwar Krupa",
    year: 2012,
  },
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : languages.filter(
        (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
//rohan end
class ContactOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnMobNumber: "",
      address: "",
      roomNo: "",
      value: "",
      suggestions: [],
      disabled: false,
      emailSent: false,
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // nodemailer start
  submitEmail = (e) => {
    e.preventDefault();

    this.setState({
      disabled: true,
      emailSent: true,
    });
    let data = {
      name: this.state.rnName,
      mobile: this.state.rnMobNumber,
      address: this.state.value,
      roomno: this.state.roomNo,
    };
    console.log(data);
    axios
      .post("https://sammyvision-backend.herokuapp.com/api/forma", data)
      .then((res) => {
        this.setState({ emailSent: false, disabled: false }, this.resetForm());
        toast.success("Request has been sent to Team !");
      })
      .catch(() => {
        console.log("Request not sent");
        toast.error(
          "Request not sent, Google reCaptcha verification expired on 31 October, 2021, Please renew"
        );
      });
  };
  resetForm() {
    this.setState({ rnName: "", rnMobNumber: "", value: "", roomNo: "" });
    setTimeout(() => {
      this.setState({
        emailSent: false,
      });
    }, 3000);
  }

  // nodemailer end
  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter your society/apartment/area Name *",
      value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <div className="contact-form--1">
        <ToastContainer />
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Raise Request</h2>
                <p className="description">
                  You are just one step away from getting your connection,
                  Please fill in below details and click on submit.
                </p>
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.submitEmail}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={(e) => {
                        this.setState({ rnName: e.target.value });
                      }}
                      placeholder="Your Name *"
                      required
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="number"
                      name="mobile"
                      id="item02"
                      value={this.state.rnMobNumber}
                      onChange={(e) => {
                        this.setState({ rnMobNumber: e.target.value });
                      }}
                      placeholder="Your Mobile Number *"
                      required
                    />
                  </label>

                  <label htmlFor="item03">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                      id="item03"
                      required
                    />
                  </label>

                  <label htmlFor="item04">
                    <input
                      type="text"
                      id="item04"
                      name="roomNo"
                      value={this.state.roomNo}
                      onChange={(e) => {
                        this.setState({ roomNo: e.target.value });
                      }}
                      placeholder="Your Room Number and Wing *"
                      required
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                    disabled={this.state.disabled}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="section-title text-left mb--15 mt--15">
                <p className="description">
                  Connect with us via phone:{" "}
                  <a href="tel:+919082151582">9082151582</a> or{" "}
                  <a href="tel:+919869443245">9869443245</a>
                </p>
                <p>
                  {" "}
                  Email:
                  <a href="mailto:sammyvision.internet@gmail.com">
                    {" "}
                    sammyvision.internet@gmail.com
                  </a>{" "}
                </p>
                <p>
                  {" "}
                  Address:
                  <a href="https://www.google.com/maps/dir//Shri%20Yogeshwar%20Co%20Operative%20Housing%20Society">
                    {" "}
                    101 - First Floor, Jaykrupa Housing Society, Near Bharat
                    Bank, Ghodapdeo, Mumbai- 400033
                  </a>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src="/assets/images/about/about-6.webp" alt="rostudio" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactOne;
